<template>
 <div>
   <a-modal
       v-model="visible"
       @ok="handleOk"
       @cancel="cancle"
       wrapClassName="cancelName"
       :width="648"
       :closable="false"
       centered
       :maskClosable="false"
   >
     <div class="modal_close" @click="cancle">
       <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
     </div>
     <div class="modal_title_box">
      <span class="modal_title">
        <i class="modal_title_line"></i>咨询
      </span>
     </div>
     <div class="InfoBox">
       <div class="phoneBox">
         <p class="tipName">咨询电话</p>
         <p class="phone">0532-86683737</p>
       </div>
     </div>
     <div class="tabBox">
       <p
           v-for="(item,index) in typeList"
           :key="index"
           @click="changeType(item)"
           :class="formData.appraiseType == item.index?'tabActive':'tab'"

       >
         {{item.name}}
         <span class="bottomBlock"></span>
       </p>
     </div>
     <div class="reasonBox">
       <p v-show="formData.appraiseType == 2"
          v-for="item in complaintList"
          :key = item.id
          @click="handleChooseOption(item)"
          :class="item.selected ?'boxActive':'box'"
       >
         {{item.name}}
       </p>
       <p v-show="formData.appraiseType !== 2"
          v-for="item in consultList"
          :key = item.id
          :class="item.selected ?'boxActive':'box'"
          @click="handleChooseOption(item)"
       >
         {{item.name}}
       </p>

     </div>
     <p class="tip">请确认您的问题类型和手机号，我们会尽快与您联系</p>
     <p class="phoneN">
       <span>手机号</span>
       <a-input
           class="input"
           v-model="formData.phone"
       />
     </p>
     <a-textarea
         class="textarea"
         :maxLength="50"
         v-model="formData.remark" :placeholder="formData.appraiseType==2?'请填写您的投诉及建议(不超过50字)':'请填写您的咨询及建议(不超过50字)'" />
   </a-modal>
   <tipModal
       class="delectBox"
       :visible.sync="reportModal"
       :info="messageInfo"
       @deleteHandOk="report"
       @deleteHandCancle="cancelReport"
   ></tipModal>
   <tipModal
       class="delectBox"
       :visible.sync="giveUpModal"
       info="确认放弃已编辑但未提交的内容？"
       @deleteHandOk="giveUp"
       @deleteHandCancle="cancelGiveUp"
   ></tipModal>
 </div>

</template>

<script>
import {queryTag, saveDeliveryData} from "@/views/order/progress/api";
import  tipModal from './tipsModal'
export default {
  components:{
    tipModal
  },
  name: "consulation-modal",
  data() {
    return {
      typeList:[
        {
          index:2,
          name:'我要投诉'
        },
        {
          index:1,
          name:'我要咨询'
        },
      ],
      complaintList:[],
      consultList:[],
      formData: {
        orderCode: '', // 订单号, P单或BHO单
        appraiseType: 2, // 2-投诉，1-咨询，默认2
        outType: 1, // 是否需要校验弹窗，咨询传1
        starNumber: '', // 星级
        starName: '', // 星级说明
        tagIds: '', // 标签id，逗号隔开
        tagNames: '', // 标签说明，逗号隔开
        phone: '', // 手机号
        remark: '', // 留言
      },
      reportModal:false,
      messageInfo:'',
      giveUpModal:false,
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    orderCode: {
      type: String,
      default: ''
    },
  },
  watch: {
    visible: {
      immediate: true,
      handler(newValue) {
        this.formData.orderCode = this.orderCode
        if(this.visible){
          this.queryTag(1) // 查询咨询标签列表
          this.queryTag(2) // 查询投诉标签列表
        }
        if(!this.visible) {
          this.formData = {
            // orderCode: '', // 订单号, P单或BHO单
              appraiseType: 2, // 2-投诉，1-咨询，默认2
              outType: 1, // 是否需要校验弹窗，咨询传1
              starNumber: '', // 星级
              starName: '', // 星级说明
              tagIds: '', // 标签id，逗号隔开
              tagNames: '', // 标签说明，逗号隔开
              phone: this.$store.state.user.userInfo.account.mobile, // 手机号
              remark: '', // 留言
          }
          this.queryTag(1) // 查询咨询标签列表
          this.queryTag(2) // 查询投诉标签列表
        }
      }
    },
    orderCode: {
      immediate: true,
      handler(newValue) {
      }
    },
  },
  mounted() {
    this.formData.phone = this.$store.state.user.userInfo.account.mobile
  },
  methods: {
    queryTag(appraiseType) {
      queryTag({
        startNumber: 0,
        appraiseType: appraiseType
      }).then(res => {
        if(res.data.code == 0) {
          const list =  res.data.date.map(el => {
            return  {
              id: el.id,
              name: el.tag,
              selected: false
            }
          })
          if(appraiseType == 1) {
            this.consultList = list
          }else {
            this.complaintList = list
          }
        }else {
          this.$message.warning(res.data.msg || '系统异常，请稍后重试')
        }
      })
    },

    handleChooseOption(item) {
      let tagIds = [], tagNames = []
      if(this.formData.appraiseType == 2) { // 投诉
        this.complaintList.forEach(el => {
          if(el.id == item.id) {
            el.selected = !item.selected
          }
          if(el.selected) {
            tagIds.push(el.id)
            tagNames.push(el.name)
          }
        })
      }else { // 咨询
        this.consultList.forEach(el => {
          if(el.id == item.id) {
            el.selected = !item.selected
          }
          if(el.selected) {
            tagIds.push(el.id)
            tagNames.push(el.name)
          }
        })
      }
      this.formData.tagIds = tagIds.toString()
      this.formData.tagNames = tagNames.toString()
    },
    changeType(item){
      this.formData.appraiseType= item.index
    },

    handleOk() {
      // 投诉-判断是否选择标签
      if(this.formData.appraiseType == 2 && this.complaintList.every(el => !el.selected)) {
        this.$message.warning('请至少选择一个标签')
        return false
      }
      // 咨询-判断是否选择标签
      if(this.formData.appraiseType == 1 && this.consultList.every(el => !el.selected)) {
        this.$message.warning('请至少选择一个标签')
        return false
      }
      if(this.formData.phone == '') {
        this.$message.warning('请填写您的手机号')
        return false
      }
      saveDeliveryData({
        ...this.formData,
        outType: this.reportModal ? 0 : 1
      }).then(res => {
        if(res.data.code == 0) {
          if(res.data.outType == 1) {
            this.messageInfo = res.data.outMessage
            this.reportModal = true
          }else {
            this.reportModal = false
            this.$message.success('提交成功')
            this.reportModal = false
            this.giveUpModal = false
            this.$emit("update:visible", false);
            this.$emit('cancelReasonHandOk')
          }
        }else {
          this.$message.warning(res.data.msg || '系统异常，请稍后重试')
        }
      })

    },
    report(){
      this.handleOk()
    },
    giveUp(){
      this.reportModal = false
      this.giveUpModal = false
      this.$emit("update:visible", false);
    },
    cancelReport(){
      this.giveUpModal = true
    },
    cancelGiveUp(){
      this.reportModal = false
      this.giveUpModal = false
    },
    cancle() {
      this.$emit("update:visible", false);


    }

  }
};
</script>
<style lang="less" scoped>
/deep/ .cancelName {
  font-family: PingFangSC-Medium, PingFang SC;

  .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;

      .InfoBox {
        .phoneBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 490px;
          height: 44px;
          background: #F2F3F5;
          border-radius: 10px;
          padding:0 24px 0 24px;
          .tipName {
            height: 20px;
            font-size: 14px;
            font-weight: 500;
            color: #262626;
            line-height: 20px;
          }
          .phone {
            height: 20px;
            font-size: 14px;
            font-weight: 500;
            color: #00AAA6;
            line-height: 20px;
          }
        }

      }
      .tabBox {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width:100%;
        margin-top: 20px;
        margin-left:106px;
        margin-bottom:16px;
        .tab{
          width: 61px;
          height: 20px;
          font-size: 14px;
          font-weight: 500;
          color: #777777;
          line-height: 20px;
          margin-right: 19px;
          cursor: pointer;
          .bottomBlock {
            display: none;
          }
        }
        .tabActive {
          width: 61px;
          height: 20px;
          font-size: 14px;
          font-weight: 500;
          color: #262626;
          line-height: 20px;
          margin-right: 19px;
          position: relative;
          cursor: pointer;
          .bottomBlock {
            width: 16px;
            height: 4px;
            background: linear-gradient(90deg, #57D9D3 0%, #2BD8B5 100%);
            border-radius: 2px;
            backdrop-filter: blur(10px);
            position: absolute;
            bottom: -7px;
            left: 23px
          }
        }

      }
      .reasonBox {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: flex-start;
        width:100%;
        margin-left:108px;
        padding-right: 108px;
        .boxActive {
          cursor: pointer;
          padding: 6px 14px;
          height: 32px;
          border-radius: 16px;
          border: 1px solid #00AAA6;
          color: #00AAA6;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin:8px 8px 0 0;
        }
        .box {
          cursor: pointer;
          padding: 6px 14px;
          height: 32px;
          border-radius: 16px;
          border: 1px solid #ccc;
          color: #777;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin:8px 8px 0 0;
        }
      }
      .tip {
        width: 100%;
        height: 17px;
        font-size: 12px;
        font-weight: 400;
        color: #AAAAAA;
        text-align: left;
        line-height: 17px;
        margin-left: 106px;margin-top: 16px
      }
      .phoneN {
        width: 100%;
        margin-left: 106px;
        text-align: left;
        margin-top: 8px;
        span{
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          padding-right:8px
        }
        .input{
          width: 284px;
          height: 32px;
          border-radius: 4px;
          border: 1px solid #EEEEEE;
        }
      }
      .textarea {
        width: 490px;
        height: 88px;
        border-radius: 4px;
        border: 1px solid #EEEEEE;
        color: #262626;
        margin-top: 20px
      }

      .modal_close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        padding-bottom: 40px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }

    p {
      font-size: 18px;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding:8px 0 30px;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }
}
</style>


