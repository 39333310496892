/*
 * @Author: mayu.ex 15163353598@163.com
 * @Date: 2022-11-03 15:11:11
 * @LastEditors: mayu.ex 15163353598@163.com
 * @LastEditTime: 2024-04-09 16:29:15
 * @FilePath: \B2B\src\views\order\channelOrder\progress\api.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import service from "@/utils/request";
import dmsService from "@/utils/dmsrequest";

//保存评价
export function saveEvaluate(data) {
  return service({
    method: "post",
    url: "/orderEvaluation/saveEvaluate.nd",
    data:data
  });
}
//获取评价
export function getEvaluate(data) {
  return service({
    method: "post",
    url: "/orderEvaluation/init.nd",
    data:data
  });
}

//获评价详情
export function getExamInfo(id) {
  return dmsService({
    url: "getPurchaseExamInfo",
    data:{
      orderId:id
    }
  });
}
export function getPurchaseOrderProcess(id) {
  return dmsService({
    url: "getPurchaseOrderProcess",
    data:{
      purchaseOrderId:id
    }
  });
}

// 获取签名
export function sign(data) {
  return service({
    method: "get",
    url: "/wuliu/sign.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    params:data
  });
}