

<template>
  <a-modal
      v-model="visible"
      @ok="handleOk"
      @cancel="cancle"
      wrapClassName="cancelName"
      :width="648"
      :closable="false"
      centered
      :maskClosable="false"
  >
    <div class="modal_close" @click="cancle">
      <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
    </div>
    <div class="modal_title_box">
      <span class="modal_title">
        <i class="modal_title_line"></i>物流评价
      </span>
    </div>
    <div class="InfoBox">
      <div class="orderOrder">
        <p>订单号：{{formData.orderCode}}</p>
<!--        <p class="tipName" @click="cancle"><span>详情</span></p>-->
      </div>
<!--      <div class="adress">-->
<!--       <p class="every left">-->
<!--         <span>市南区</span>-->
<!--         <span>仓管员</span>-->
<!--       </p>-->
<!--        <p class="every middle">-->
<!--          <span>已发货</span>-->
<!--          <img src="@/assets/order/jiantou.png" alt="">-->
<!--        </p>-->
<!--        <p class="every left">-->
<!--          <span>市北区</span>-->
<!--          <span>爱丽丝</span>-->
<!--        </p>-->
<!--      </div>-->
    </div>
    <div class="consulting-box">
      <div class="evaluate-title">您对该订单的物流服务是否满意？</div>
      <div class="evaluate-star-list">
        <div v-for="item in evaluateStatusList"  :key=item.id>
          <div class="evaluate-star-item" @click="handleChooseStar(item)" :class="formData.starNumber == item.id ? 'active' : '' ">
            <div  class="icon iconfont icon-yishoucang star-item " :class="formData.starNumber >= item.id ? 'start-active' : '' "></div>
            <p style="
height: 14px;
font-size: 10px;
font-weight: 400;
color: #AAAAAA;
line-height: 14px;">{{ item.name }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="reasonBox" v-if="formData.starNumber">
      <p
          v-for="(item,index) in evaluateList"
          :key="index"
          :class="item.selected?'boxActive':'box'"
          @click ="handleChooseOption(item)"

      >
        {{item.name}}
      </p>
    </div>
    <a-textarea
        class="textarea"
        :maxLength="50"
        v-model="formData.remark" placeholder="请填写您的留言(不超过50字)" />

  </a-modal>
</template>

<script>
import {queryTag, saveDeliveryData} from "@/views/order/progress/api";
export default {
  name: "logisticsEvaluationModal",
  data() {
    return {
      evaluateStatusList: [
        {id: 1, name: '非常不满意'},
        {id: 2, name: '不满意'},
        {id: 3, name: '一般般'},
        {id: 4, name: '满意'},
        {id: 5, name: '非常满意'},
      ],
      evaluateList: [], // 物流评价标签
      formData: {
        orderCode: '', // 订单号
        appraiseType: 3, // 评价
        outType: 0, // 是否需要校验弹窗，咨询需要传，评价默认0
        starNumber: '', // 星级
        starName: '', // 星级说明
        tagIds: '', // 标签id，逗号隔开
        tagNames: '', // 标签说明，逗号隔开
        remark: '', // 留言
      }
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    orderCode: {
      type:  String,
      default:''

    }
  },
  watch: {
    visible: {
      immediate: true,
      handler(newValue) {
        this.formData.orderCode = this.orderCode
        if(!this.visible){
          this.formData = {
            // orderCode: '', // 订单号
            appraiseType: 3, // 评价
            outType: 0, // 是否需要校验弹窗，咨询需要传，评价默认0
            starNumber: '', // 星级
            starName: '', // 星级说明
            tagIds: '', // 标签id，逗号隔开
            tagNames: '', // 标签说明，逗号隔开
            remark: '', // 留言
          }
          this.evaluateList = []
        }
      }
    },
    orderCode: {
      immediate: true,
      handler(newValue) {

      }
    },
  },
  mounted() {

  },
  methods: {
    // 物流星级评分
    handleChooseStar(item) {
      this.formData.starNumber = item.id
      this.formData.starName = item.name
       queryTag({
          starNumber: item.id,
          appraiseType: 3
        }).then(res => {
          if(res.data.code == 0) {
            const list = res.data.date.map(el => {
              return {
                id: el.id,
                name: el.tag,
                selected: false
              }
            })
            this.evaluateList = list
          }else {
            this.$message.warning(res.data.msg || '系统异常，请稍后重试')
          }
        })
    },
    // 选择评价问题
    handleChooseOption(item){
      let tagIds = [], tagNames = []
      this.evaluateList.forEach(el => {
        if(el.id == item.id) {
          el.selected = !item.selected
        }
        if(el.selected) {
          tagIds.push(el.id)
          tagNames.push(el.name)
        }
      })
      this.formData.tagIds = tagIds.toString()
      this.formData.tagNames = tagNames.toString()
    },
    handleOk() {
      if(this.formData.starNumber == '') {
        this.$message.warning('请您对该订单的物流服务进行评分')
        return false
      }
      // 判断是否选择标签
      if(this.evaluateList.every(el => !el.selected)) {
        this.$message.warning('请至少选择一个标签')
        return false
      }
      saveDeliveryData({
        ...this.formData
      }).then(res => {
        if(res.data.code == 0) {
          this.$message.success('提交成功')
          this.$emit("update:visible", false);
          this.$emit('cancelReasonHandOk')
        }else {
          this.$message.warning(res.data.msg || '系统异常，请稍后重试')
        }
      })
      //
      // this.$emit("update:visible", false);
      // this.$emit("cancelReasonHandOk", );

    },
    cancle() {
      this.$emit("update:visible", false);
    }

  }
};
</script>
<style lang="less" scoped>
/deep/ .cancelName {
  .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;

      .InfoBox {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 490px;
        //height: 114px;
        background: #F2F3F5;
        border-radius: 10px;
        padding: 18px 0;
        .orderOrder {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
          padding: 0 24px 0 24px;
          width: 100%;
          p:first-child{
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #262626;
            line-height: 20px;
          }
          p:last-child{
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            cursor: pointer;
          }
        }
        .adress {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          margin-top: 16px;
          width: 100%;
          .every {
            display: flex;
            flex-direction: column;
          }
          .left{
            span:first-child{
              height: 25px;
              font-size: 18px;
              font-weight: 500;
              color: #262626;
              line-height: 25px;
            }
            span:last-child{
              height: 17px;
              font-size: 12px;
              font-weight: 400;
              color: #AAAAAA;
              line-height: 17px;
            }
          }
          .middle {
            position: relative;
            margin:0 20px;
            span:first-child {
              height: 17px;
              font-size: 12px;
              font-weight: 500;
              color: #AAAAAA;
              line-height: 17px;
              position: absolute;
              left: 27px;
              bottom: 10px;
            }
            img {
              width: 100px;
            }
            
          }
        }
      }
      .consulting-box{
        width: 490px;
        height: 112px;
        background: #FFFAF6;
        border-radius: 6px;
        margin-top:20px;
        margin-bottom: 12px;
        display:flex;
        flex-direction: column;
        align-items: center;

        .evaluate-title{
          font-size: 16px;
          color: #262626;
          line-height: 22px;
          padding-top: 12px;
          width: 240px;
          height: 22px;
        }
        .evaluate-star-list{
          margin-top: 16px;
          display: flex;
          align-items: center;
          //justify-content: space-between;
          .evaluate-star-item{
            width: 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            font-weight: 400;
            color: #AAAAAA;
            .star-item{
              cursor: pointer;
              font-size: 30px;
              color: #CCCCCC;
              margin-bottom: 2px;
              font-weight: 400;
            }
            .start-active{
              color: #FF8F3C;
              font-weight: 400;
            }
            &.active{
              color: #FF8F3C;
              font-weight: 400;
            }
          }
        }
        .consulting-desc-list{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .consulting-desc-item{
            margin-top: 8px;
            width: 116px;
            height: 30px;
            border-radius: 15px;
            border: 1px solid #CCCCCC;
            font-size: 14px;
            font-weight: 400;
            color: #777777;
            line-height: 30px;
            text-align: center;
            &.active{
              color: #00AAA6;
              border-color: #00AAA6;
            }
          }
        }
        .consulting-message{
          position: relative;
          margin-top: 18px;
          height: 88px;
          background: #F7F7F7;
          border-radius: 8px;
          .message-textarea{
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            padding: 14px 12rpx;
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            color: #262626;
          }
          .words-num{
            position: absolute;
            right: 12px;
            bottom: 14px;
            z-index: 1;
            font-size:12px;
            font-weight: 400;
            color: #AAAAAA;
            line-height: 12px;
          }
        }
      }
      .tabBox {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width:100%;
        margin-top: 20px;
        margin-left:106px;
        margin-bottom:16px;
        .tab{
          width: 61px;
          height: 20px;
          font-size: 14px;
          font-weight: 500;
          color: #777777;
          line-height: 20px;
          margin-right: 19px;
          cursor: pointer;
          .bottomBlock {
            display: none;
          }
        }
        .tabActive {
          width: 61px;
          height: 20px;
          font-size: 14px;
          font-weight: 500;
          color: #262626;
          line-height: 20px;
          margin-right: 19px;
          position: relative;
          cursor: pointer;
          .bottomBlock {
            width: 16px;
            height: 4px;
            background: linear-gradient(90deg, #57D9D3 0%, #2BD8B5 100%);
            border-radius: 2px;
            backdrop-filter: blur(10px);
            position: absolute;
            bottom: -7px;
            left: 23px
          }
        }

      }
      .reasonBox {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: flex-start;
        width:100%;
        margin-left:108px;
        padding-right: 108px;
        .boxActive {
          cursor: pointer;
          padding: 6px 14px;
          height: 32px;
          border-radius: 16px;
          border: 1px solid #00AAA6;
          color: #00AAA6;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin:8px 8px 0 0;
        }
        .box {
          cursor: pointer;
          padding: 6px 14px;
          height: 32px;
          border-radius: 16px;
          border: 1px solid #ccc;
          color: #777;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin:8px 8px 0 0;
        }
      }
      .tip {
        width: 100%;
        height: 17px;
        font-size: 12px;
        font-weight: 400;
        color: #AAAAAA;
        text-align: left;
        line-height: 17px;
        margin-left: 106px;margin-top: 16px
      }
      .phoneN {
        width: 100%;
        margin-left: 106px;
        text-align: left;
        margin-top: 8px;
        span{
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          padding-right:8px
        }
        .input{
          width: 284px;
          height: 32px;
          border-radius: 4px;
          border: 1px solid #EEEEEE;
        }
      }
      .textarea {
        width: 490px;
        height: 88px;
        border-radius: 4px;
        border: 1px solid #EEEEEE;
        margin-top: 20px;
        color: #262626;
      }

      .modal_close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        padding-bottom: 40px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }

    p {
      font-size: 18px;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding:8px 0 30px;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }
}
</style>


